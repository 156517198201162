<!-- 厂家管理 -->
<template>
	<div class="doctor_management margin-20">
		<el-row>
			<el-col :span="24">
				<div class="grid-content">
					<template>
						<div class="form-style">
							<el-form ref="formInline" :inline="true" :model="formInline" label-width="150px" class="demo-form-inline">
                <el-form-item label="厂家/电极名称/电极型号" prop="select">
                  <el-cascader :options="selects" :props="{ checkStrictly: true }" clearable v-model="formInline.select"></el-cascader>
                </el-form-item>
								<el-form-item>
									<el-button type="primary" @click="search">搜索</el-button>
									<el-button @click="resetForm('formInline')">重置</el-button>
									<el-button type="primary" @click="addEdit('新增',{})">新增</el-button>
								</el-form-item>
							</el-form>
						</div>
						<div class="tableMargin">
							<el-table ref="multipleTable" :header-cell-style="{background:'#eef1f6'}" :data="pageData" style="width: 100%">
                <el-table-column align="center" prop="manufacturerName" label="厂家"></el-table-column>
                <el-table-column align="center" prop="electrodeName" label="电极名称"></el-table-column>
                <el-table-column align="center" prop="electrodeModelName" label="电极型号"></el-table-column>
                <el-table-column align="center" prop="productName" label="产品名称"></el-table-column>
                <el-table-column align="center" prop="productModel" label="产品型号"></el-table-column>
                <el-table-column align="center" prop="productDescription" label="产品描述"></el-table-column>
                <el-table-column align="center" label="状态">
                  <template slot-scope="scope">
                    <label :style="scope.row.status== 200000?'color:#67C23A;':'color:#F56C6C;'">{{scope.row.status==200000?'已启用':'已停用'}}</label>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="200px">
                  <template slot-scope="scope">
                    <template>
                      <el-button :type="scope.row.status==200000 ? 'danger' :'primary'" @click="addEdit('状态', scope.row)">{{scope.row.status==200000 ? '停用' :'启用'}}</el-button>
                      <el-button type="primary" :type="scope.row.status!=200000 ? 'info' :'primary'" @click="addEdit('编辑',scope.row)">编辑</el-button>
                    </template>
                  </template>
                </el-table-column>
							</el-table>
						</div>
						<v-paging v-on:childPage="pages" :total=total></v-paging>
					</template>
				</div>
			</el-col>
		</el-row>
    <!-- 编辑新增模态框 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="40%" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="厂家/电极名称/电极型号" prop="select">
          <el-cascader :options="selects" v-model="ruleForm.select" style="width:100%"></el-cascader>
        </el-form-item>
        <el-form-item label="产品名称" prop="productName">
          <el-input clearable v-model="ruleForm.productName"></el-input>
        </el-form-item>
        <el-form-item label="产品型号" prop="productModel">
          <el-input clearable v-model="ruleForm.productModel"></el-input>
        </el-form-item>
        <el-form-item label="产品描述" prop="productDescription">
          <el-input clearable v-model="ruleForm.productDescription"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="saveUpdate('ruleForm')">确 定</el-button>
			</span>
    </el-dialog>
	</div>
</template>

<script>
	import vPaging from '../common/Paging.vue' //引入分页组件
	export default {
		components: {
			vPaging
		},
		methods: {
			//解决输入框无法输入的问题
			change(e){
				this.$forceUpdate();
			},

      //产品分页接口
      async queryPage(current = 1, size = 10){
        let info={
          current:current,
          size:size,
          manufacturerId:this.formInline.select&&this.formInline.select.length>=1 ? this.formInline.select[0] : "",
          electrodeNameId:this.formInline.select&&this.formInline.select.length>=2 ? this.formInline.select[1] : "",
          electrodeModelId:this.formInline.select&&this.formInline.select.length>=3 ? this.formInline.select[2] : "",
          menuId: this.menuCode,
        }
        let res = await this.$Http.ProductQueryPage(info)
        if (res.code == '200') {
          this.pageData = res.data.data;
          this.total = res.data.total;
        } else {
          this.pageData = [];
          this.total = 0;
          this.$message.error(res.message);
        }
      },

      //编辑新增
      addEdit(val, item) {
        this.ruleForm = JSON.parse(JSON.stringify(item));

        if(val=="状态"){
          item.status=="200000" ? this.status="200001" : this.status="200000";
          this.update();
        }else{
          if(item.status=="200001"){
            this.$message.error("已停用,不可编辑");
            return false;
          }else{
            this.dialogVisible = true;
            this.title = `${val}产品`;
            this.status=item.status;

            //编辑时回显
            if(val == '编辑'){
              this.ruleForm.select=[this.ruleForm.manufacturerId,this.ruleForm.electrodeNameId,this.ruleForm.electrodeModelId];
            }
          }
        }
      },

      //下拉列表
      async select(level){
        let obj={
          menuId: this.menuCode,
          level: level
        }
        let res = await this.$Http.Select(obj);
        if (res.code == '200') {
          this.selects = res.data;
        } else {
          this.$message.error(res.message);
        }
      },

      //保存
      saveUpdate(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.title=="新增产品" ? this.insert() : this.update();
          }
        });
      },

      //新增产品接口
      async insert(){
        let info={
          productName:this.ruleForm.productName,
          productModel:this.ruleForm.productModel,
          productDescription:this.ruleForm.productDescription,
          manufacturerId:this.ruleForm.select&&this.ruleForm.select.length>=1 ? this.ruleForm.select[0] : "",
          electrodeModelId:this.ruleForm.select&&this.ruleForm.select.length>=3 ? this.ruleForm.select[2] : "",
        }
        let res = await this.$Http.ProductInsert(info);
        this.dialogVisible=false;
        if (res.code == '200') {
          this.queryPage();
        } else {
          this.$message.error(res.message);
        }
      },

      //修改产品接口
      async update(){
        let info={
          manufacturerProductId:this.ruleForm.manufacturerProductId,
          productName:this.ruleForm.productName,
          productModel:this.ruleForm.productModel,
          productDescription:this.ruleForm.productDescription,
          manufacturerId:this.ruleForm.select&&this.ruleForm.select.length>=1 ? this.ruleForm.select[0] : "",
          electrodeModelId:this.ruleForm.select&&this.ruleForm.select.length>=3 ? this.ruleForm.select[2] : "",
          status:this.status
        }
        let res = await this.$Http.ProductUpdate(info);
        this.dialogVisible=false;
        if (res.code == '200') {
          this.queryPage();
        } else {
          this.$message.error(res.message);
        }
      },
			//分页组件
			pages(current, size) {
        this.queryPage(current, size);
			},

			// 搜索
      search() {
        this.queryPage();
			},

			//重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			}
		},
		data() {
			return {
				menuCode: JSON.parse(localStorage.getItem('menuList'))[this.$route.name],
        newStatus:false,
        title: "",
        formInline: {},
        total: 0,
        pageData: [],
        selects:[],
        dialogVisible: false,
				ruleForm: {
          select:'',
          productName:'',
          productModel:''
        },
				rules: {
          select: [
            { required: true, message: "请选择厂家/电极名称/电极型号", trigger: "blur" },
          ],
          // productName: [
          //   { required: true, message: "产品名称不能为空", trigger: "blur" },
          // ],
          // productModel: [
          //   { required: true, message: "产品型号不能为空", trigger: "blur" },
          // ],
        }
			}
		},
		created() {},
		mounted() {
			//分页
			this.queryPage();

      this.select(3);
		}
	}
</script>

<style scoped>
</style>
